<template>
  <v-dialog
    v-model="dialog"
    max-width="800px"
    width="80%"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    @input="close"
  >
    <v-card height="400px">
      <v-card-title>Product Lookup</v-card-title>
      <v-card-text>
        <div class="d-flex flex-column flex-md-row justify-space-between">
            <treeselect
              v-if="productTree && productTree.length > 0"
              :multiple="false"
              :options="productTree"
              :disable-branch-nodes="true"
              :show-count="true"
              @input="onUpdate"
            />
        </div>
      </v-card-text>
      <v-card-actions class="mt-10">
        <v-spacer />
        <v-btn
          class="mb-5"
          @click="submit"
          :disabled="!product"
          color="primary"
        >
          Submit Selected
        </v-btn>
        <v-btn
          class="mb-5"
          @click="
            dialog = false;
            close();
          "
        >
          Cancel
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Treeselect from "@riophae/vue-treeselect";
// import the styles
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  props: {
    value: {
      type: [String, Number, Object],
      required: false,
      default: null,
    },
    activated: {
      type: [Boolean],
      required: false,
      default: false,
    },
    returnObject: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
    activated(newValue) {
      if (newValue) {
        if (this.value) {
          if (this.returnObject) {
            this.productId = this.value.id;
          } else {
            this.productId = this.value;
          }
        } else {
          this.product = null;
          this.productId = null;
        }
        this.dialog = true;
      }
    },
  },
  computed: {
    ...mapGetters("navigation", ["isAppLoading"]),
    ...mapGetters("products", ["products"]),
  },
  components: {
    Treeselect,
  },
  data: () => {
    return {
      dialog: false,
      productId: null,
      product: null,
      productTree: [],
    };
  },
  mounted() {
    this.loadProducts().then((resp) => {
      this.productTree = resp.filter((product) => product.level == 1);
    });
  },
  methods: {
    ...mapActions("messages", [
      "addErrorMessage",
      "addMessage",
      "addSuccessMessage",
    ]),
    ...mapActions("products", ["loadProducts"]),
    close() {
      this.$emit("input", null);
      this.$emit("update:activated", false);
    },
    submit() {
      this.dialog = false;
      if (this.returnObject) {
        this.$emit("input", this.product);
      } else {
        this.$emit("input", this.product.id);
      }
      this.$emit("selected", this.product);
      this.$emit("update:activated", false);
    },
    onUpdate(productId) {
      this.product = this.products.find((p) => p.id == productId);
    },
  },
};
</script>

<style></style>
